/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnChanges, Output, EventEmitter, Input, ViewChild, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event as RouterEvent } from '@angular/router';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs';

// ngZorro services
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { GuildService } from 'src/app/services/guild.service';
import { InviteService } from 'src/app/services/invite.service';
import { LocalStorageService } from 'ngx-localstorage';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { RoutingStateService } from 'src/app/services/routing-state.service';

// components
import { GuildTopActivitiesComponent } from '../guild-top-activities/guild-top-activities.component';
import { SharedPlaylistComponent } from '../shared-playlist/shared-playlist.component';

// models
import { IGuild, encodeUrlComponent } from '../../models/guild.model';
import { IGuildExternalLink } from '../../models/guild-external-link.model';
import { IGuildMember } from '../../models/guild-member.model';
import { IUserProfilePublic, IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { LinksState } from '../../../models/links-state.model';
import { IInvitation } from 'src/app/models/invitation.model';

// types
import { EditMode } from 'src/app/generic-components/types/edit-mode.type';
import { EGuildRole } from '../../types/guild-role.type';
import { EGuildVisibilityType } from 'src/app/modules-guilds/types/guild-visibility.type';
import { GuildMemberStatus } from '../../types/guild-member-status.type';
import { MetaTagService } from 'src/app/services/meta-tag.service';
export interface LastSelectedTab {
  guildId: number;
  lastSelectedTab: string;
}
export interface GuildRoleProperties {
  type: EGuildRole;
  name: string;
  icon: string;
}
export interface DropListData {
  members: string[];
  role: number;
}
export interface CheckedGuildMember {
  guildMember: IGuildMember;
  checked: boolean;
}

export interface PublicUrlResponse {
  userId: string;
  publicUrl: string;
}
export interface LastVisitedGuild {
  id: number;
  userId: string;
}
@Component({
  selector: 'sl-guild-home',
  templateUrl: './guild-home.component.html',
  styleUrls: ['./guild-home.component.less'],
})
export class GuildHomeComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('userSearchInput') userSearchInputField!: ElementRef;
  @ViewChild(SharedPlaylistComponent) sharedPlaylistComponent!: SharedPlaylistComponent;
  @ViewChild(GuildTopActivitiesComponent) guildTopActivitiesComponent!: GuildTopActivitiesComponent;
  @Output() selectGuild = new EventEmitter<IGuild>();
  @Output() userLeftGuild = new EventEmitter<IGuild>();
  @Output() handleJoiningGuild = new EventEmitter<IGuild>();
  @Output() handleLeavingGuild = new EventEmitter<IGuild>();
  @Output() handlePermissionToConductSearch = new EventEmitter<boolean>();
  @Input() guild!: IGuild;
  @Input() isAdmin = false;

  // Guild
  isLoading = false;
  isPrivate = false;
  selectMode = false;
  guildMenuOptionsVisible = false;
  isGuildAccessPopConfirmVisible = false;
  guildVisibilityType = EGuildVisibilityType;
  guildUrlName!: string;
  guildToBeEdited!: IGuild;
  editMode = EditMode;
  editGuildMode: EditMode = EditMode.new;
  modeType = EditMode;
  placeholderImage = '/assets/img/placeholder_image.png';
  goalId!: number;
  playlistId!: number;
  activityId!: number;

  // User Search
  isSearchingForUsers = false;
  userSearchInputValue: string = '';
  searchForUsersSubject: Subject<string> = new Subject();
  blurSubject: Subject<void> = new Subject();
  isLoadingUserSearchResults = false;
  userSearchResults!: IUserProfilePublic[];
  selectedMatchedUserPublicUrl: string | null = null;
  isMatchedUserSelected = false;

  // Links
  editGuildLinks = false;
  linksUnedited: IGuildExternalLink[] = [];
  linksEdited: IGuildExternalLink[] = [];
  linksState: LinksState = { amount: 3, expanded: true } as LinksState;

  // Tabs
  tabs = new BehaviorSubject<string[]>(['about', 'playlists', 'activities']);
  tabInformation = {} as LastSelectedTab;
  selectedTab = new BehaviorSubject<string>(this.tabs.value[0]);

  // Members
  isAGuildMember = false;
  isAGuildOwner = false;
  currentUser: IUserProfileSubscription = {} as IUserProfileSubscription;
  currentGuildMember: IGuildMember = {} as IGuildMember;
  guildMembers: IGuildMember[] | undefined = undefined;
  filteredGuildMembersByStatus: IGuildMember[] = [];
  guildWaitlistMembers: IGuildMember[] = [];
  selectedGuildMember: IGuildMember | null = null;
  selectedGuildMembers = new BehaviorSubject<IGuildMember[]>([]);
  memberRoleOwners: CheckedGuildMember[] = [];
  memberRoleLeaders: CheckedGuildMember[] = [];
  memberRoleMembers: CheckedGuildMember[] = [];
  memberRoleNewbies: CheckedGuildMember[] = [];
  selectedRoleOwners: IGuildMember[] = [];
  selectedRoleLeaders: IGuildMember[] = [];
  selectedRoleMembers: IGuildMember[] = [];
  selectedRoleNewbies: IGuildMember[] = [];
  guildMemberStatus = GuildMemberStatus;
  playlistActivitiesAddedByMembers: IGuild[] = [];
  guildMemberSelectedStates = new Map<number, boolean>();
  guildRoleType = EGuildRole;
  guildRoleProperties: Map<EGuildRole, GuildRoleProperties> = new Map([
    [EGuildRole.Owner, { type: EGuildRole.Owner, name: 'Owner', icon: 'fa-solid fa-crown fa-lg' }],
    [EGuildRole.Leader, { type: EGuildRole.Leader, name: 'Leader', icon: 'fa-solid fa-user-shield fa-lg' }],
    [EGuildRole.Member, { type: EGuildRole.Member, name: 'Member', icon: 'fa-solid fa-user fa-lg' }],
    [EGuildRole.Newbie, { type: EGuildRole.Newbie, name: 'Newbie', icon: 'fa-sharp fa-solid fa-person-drowning fa-lg' }],
  ]);

  subscription: Subscription = new Subscription();

  metaTags = [];

  constructor(
    private guildService: GuildService,
    private route: ActivatedRoute,
    private routingStateService: RoutingStateService,
    private router: Router,
    private clipboard: Clipboard,
    private message: NzMessageService,
    private accountDetailsService: AccountDetailsService,
    private inviteService: InviteService,
    private localStorageService: LocalStorageService,
    private modal: NzModalService,
    private location: Location,
    private publicProfileService: PublicProfileService,
    private nzContextMenuService: NzContextMenuService,
    private metaTagService: MetaTagService
  ) { }

  ngOnChanges() {
    this.getGuild();
    this.initialiseQueryParameters();
  }

  ngOnInit(): void {
    this.subscription = combineLatest([this.selectedGuildMembers, this.selectedTab])
      .subscribe(([selectedGuildMembers, selectedTab]) => {
        const ids = selectedGuildMembers.map(member => member.id);
        this.filterSelectedGuildRoles();
        this.selectMode = ids.length > 0;
        if (this.selectMode) {
          if (selectedTab === 'playlists') {
            setTimeout(() => {
              this.sharedPlaylistComponent.getSharedPlaylistsAdded();
            })
          } else if (selectedTab === 'activities') {
            setTimeout(() => {
              this.guildTopActivitiesComponent.getTopActivitiesCompleted();
            })
          }
        }
      });
    this.monitorRouteChanges();
    this.initialiseUserSearchSubscription();
    this.initialiseBlurSubscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.searchForUsersSubject.unsubscribe();
    this.blurSubject.unsubscribe();
  }

  monitorRouteChanges() {
    this.subscription.add(
      this.router.events.pipe(
        filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.urlAfterRedirects === '/guilds'),
      ).subscribe(() => {
        this.handlePermissionToConductSearch.emit(true);
      })
    );
  }

  selectGuildMember(member: IGuildMember) {
    this.selectedGuildMember = member;
  }

  initialiseUserSearchSubscription(): void {
    this.searchForUsersSubject.pipe(
      debounceTime(750),
      distinctUntilChanged()
    ).subscribe(() => {
      this.conductUserSearch();
    });
  }

  initialiseBlurSubscription(): void {
    this.blurSubject.pipe(debounceTime(300)).subscribe(() => {
      if (!this.isMatchedUserSelected) {
        this.searchForUsers();
      }
    });
  }

  initialiseQueryParameters() {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe({
        next: (params) => {
          this.goalId = params.goalId;
          this.playlistId = params.playlistId;
          this.activityId = params.activityId;
        }
      });
    } else {
      console.log(`QueryParams on the route should be initialised, but if it's not, it might be because we haven't setup the test case correctly`);
    }
  }

  submitNewlyAddedLink(link: IGuildExternalLink): void {
    link.guildId = this.guild.id;
    this.guildService.addGuildLink(link).subscribe({
      next: (l) => {
        this.guild.externalLinks.unshift(l);
        this.guild.externalLinks = [...this.guild.externalLinks];
        this.message.success(`Link has been successfully added`);
      },
      error: (error) => {
        console.error(`Failed to add link. Error: ${error.message}`);
      },
    });
  }

  deleteLink(link: IGuildExternalLink): void {
    if (!link.guildId) {
      link.guildId = this.guild.id;
    }
    this.guildService.deleteGuildLink(link).subscribe({
      next: (result) => {
        const index = this.guild.externalLinks.indexOf(link);
        if (result) {
          this.guild.externalLinks.splice(index, 1);
          this.message.success(`Link has been successfully deleted`);
        }
      },
      error: (error) => {
        console.error(`Failed to delete link. Error: ${error.message}`);
      },
    });
  }

  submitEditedLink(link: IGuildExternalLink): void {
    link.guildId = this.guild.id;
    this.guildService.editGuildLinks(link).subscribe({
      next: (l) => {
        const index = this.guild.externalLinks.indexOf(link);
        this.guild.externalLinks[index] = l;
        this.message.success(`Links have been successfully edited`);
      },
      error: (error) => {
        console.error(`Failed to edit links. Error: ${error.message}`);
      },
    });
  }

  selectGuildMemberAvatar(memberAvatar: CheckedGuildMember) {
    this.selectMode = true;
    memberAvatar.checked = !memberAvatar.checked;

    const currentlySelectedMembers = this.selectedGuildMembers.value;

    if (memberAvatar.checked && this.isUserAGuildMember(memberAvatar.guildMember)) {
      currentlySelectedMembers.push(memberAvatar.guildMember);
      this.guildMemberSelectedStates.set(memberAvatar.guildMember.id, true);
    } else {
      if (currentlySelectedMembers.includes(memberAvatar.guildMember)) {
        const index = currentlySelectedMembers.indexOf(memberAvatar.guildMember);
        currentlySelectedMembers.splice(index, 1);
        this.guildMemberSelectedStates.set(memberAvatar.guildMember.id, false);
      }
    }

    this.selectedGuildMembers.next(currentlySelectedMembers);
  }

  exitSelectMode() {
    if (this.selectMode) {
      this.deselectAllGuildMembers();
      this.selectedGuildMember = null;
      this.selectMode = false;
    }
  }

  deselectAllGuildMembers() {
    this.resetGuildMemberSelectedStates();
    this.selectedGuildMembers.next([]);
    this.memberRoleOwners.forEach(member => member.checked = false);
    this.memberRoleLeaders.forEach(member => member.checked = false);
    this.memberRoleMembers.forEach(member => member.checked = false);
    this.memberRoleNewbies.forEach(member => member.checked = false);
  }

  filterSelectedGuildRoles() {
    this.selectedRoleLeaders = this.selectedGuildMembers.value.filter(member => member.memberType === EGuildRole.Owner);
    this.selectedRoleLeaders = this.selectedGuildMembers.value.filter(member => member.memberType === EGuildRole.Leader);
    this.selectedRoleMembers = this.selectedGuildMembers.value.filter(member => member.memberType === EGuildRole.Member);
    this.selectedRoleNewbies = this.selectedGuildMembers.value.filter(member => member.memberType === EGuildRole.Newbie);
  }

  toggleGuildMenuOptions() {
    this.guildMenuOptionsVisible = !this.guildMenuOptionsVisible;
    this.isGuildAccessPopConfirmVisible = false;
  }

  selectToggle(event: Event) {
    this.isGuildAccessPopConfirmVisible = true;
    event.stopPropagation();
  }

  changeGuildAccess(event: Event) {
    const newVisibilityType = this.guild.visibility === this.guildVisibilityType.Public ? this.guildVisibilityType.Private : this.guildVisibilityType.Public;
    this.guildService.changeGuildAccess(this.guild.id, newVisibilityType).subscribe({
      next: (res) => {
        this.guild.visibility = res.visibility;
        this.isPrivate = !this.isPrivate;
        if (this.guildWaitlistMembers.length) {
          this.guildWaitlistMembers.forEach(guildWaitlistMember => {
            this.approveGuildWaitlistUser(guildWaitlistMember.userId);
          })
        }
        this.isGuildAccessPopConfirmVisible = false;
      },
      error: (error) => {
        this.message.error('Failed to change guild visibility type', error);
      }
    });
    event.stopPropagation();
  }

  cancelToggle(event: Event) {
    this.isGuildAccessPopConfirmVisible = false;
    event.stopPropagation();
  }

  isPublicGuild() {
    return this.guild.visibility === this.guildVisibilityType.Public;
  }

  drop(event: CdkDragDrop<DropListData>) {
    const memberUserId: string = event.item.data;
    let currentMemberRole = this.currentGuildMember.memberType;

    if (this.isAGuildOwner) {
      currentMemberRole = this.guildRoleProperties.get(this.guildRoleType.Owner)!.type;
    }

    const roles = {
      Newbie: this.guildRoleProperties.get(this.guildRoleType.Newbie)!.type,
      Member: this.guildRoleProperties.get(this.guildRoleType.Member)!.type,
      Leader: this.guildRoleProperties.get(this.guildRoleType.Leader)!.type,
      Owner: this.guildRoleProperties.get(this.guildRoleType.Owner)!.type
    };

    const prevRole = event.previousContainer.data.role;
    const newRole = event.container.data.role;

    const permissions = {
      [roles.Owner]: [roles.Newbie, roles.Member, roles.Leader, roles.Owner],
      [roles.Leader]: [roles.Newbie, roles.Member, roles.Leader],
      [roles.Member]: [roles.Newbie, roles.Member],
      [roles.Newbie]: []
    };

    if (event.container !== event.previousContainer) {
      if (memberUserId === this.currentGuildMember.userId) {
        this.message.warning('You cannot move yourself');
        return;
      }

      if (!permissions[currentMemberRole].includes(newRole) || (currentMemberRole === roles.Member && prevRole > newRole)) {
        this.message.warning('You do not have permission to move this member');
        return;
      }

      if (prevRole === this.guildRoleProperties.get(this.guildRoleType.Owner)!.type && (memberUserId === this.guild.createdById)) {
        this.message.warning('You cannot move this owner');
        return;
      }

      const guildRoleArrays = [this.memberRoleNewbies, this.memberRoleMembers, this.memberRoleLeaders, this.memberRoleOwners];

      this.removeMemberFromPreviousGuildRole(guildRoleArrays[prevRole], memberUserId);
      this.assignMemberNewGuildRole(memberUserId, event.container.data.role);
    }
  }

  removeMemberFromPreviousGuildRole(roleArray: CheckedGuildMember[], userId: string): void {
    const memberIndex = roleArray.findIndex(member => member.guildMember.userId === userId);
    if (memberIndex !== -1) {
      roleArray.splice(memberIndex, 1);
    } else {
      console.error(`Cannot find user with id: ${userId} in role array`);
    }
  }

  assignMemberNewGuildRole(guildMemberId: string, roleValue: number) {
    this.guildService.assignGuildRole(this.guild.id, roleValue, guildMemberId).subscribe({
      next: (res) => {
        if (res) {
          this.getGuildMembers();
          this.message.success('Successfully assigned role');
        }
      },

      error: (error) => {
        this.message.error('Failed to assign role', error.message);
      }
    });
  }

  handleCancel(): void {
    this.editGuildLinks = false;
  }

  expandLinks(): void {
    this.linksState = { id: this.guild.id, amount: this.guild.externalLinks.length, expanded: true };
    this.removeLinkState();
  }

  collapseLinks(): void {
    this.linksState = { id: this.guild.id, amount: 3, expanded: false };
    this.saveLinkState();
  }

  getLinkState(): void {
    const linksState = this.localStorageService.get<LinksState>('expandedLinksStateID: ' + this.guild.id);
    if (linksState && linksState.expanded === false) {
      this.linksState = linksState;
    } else {
      this.linksState = { id: this.guild.id, amount: this.guild.externalLinks.length, expanded: true };
    }
  }

  saveLinkState(): void {
    this.localStorageService.set('expandedLinksStateID: ' + this.guild.id, this.linksState);
  }

  removeLinkState(): void {
    this.localStorageService.remove('expandedLinksStateID: ' + this.guild.id);
  }

  getGuildImage() {
    return this.guild.imageUrl;
  }

  getGuildPlaceholderImage() {
    return this.placeholderImage;
  }

  getGuild() {
    if (this.guild.id) {
      this.setupGuildHomepage();
    } else {
      this.message.error('Error, failed to load guild');
      this.router.navigate(['/guilds']);
    }
  }

  setupGuildHomepage() {
    this.resetGuildElements();
    this.getGuildMembers();
    this.setUpTabs();
    if (this.guild.externalLinks) {
      this.getLinkState();
    }
    this.setVisibilityStatus();
    this.setMetaTags(this.guild.name, this.guild.description, this.guild.imageUrl);
    this.selectGuild.emit(this.guild);
  }

  navigateToUserProfile(userId: string) {
    this.publicProfileService.getSelectedUserPublicUrl(userId).subscribe({
      next: (res: PublicUrlResponse) => {
        this.exitSelectMode();
        window.open(`/profile/${res.publicUrl}`, '_blank');
      },
      error: (error: HttpErrorResponse) => {
        console.error(`Failed to get the public URL of the selected user. Error: ${error}`);
      }
    });
  }

  viewGuildMemberContextMenu(event: MouseEvent, menu: NzDropdownMenuComponent, guildMember: IGuildMember) {
    event.preventDefault();
    this.selectGuildMember(guildMember);
    this.nzContextMenuService.create(event, menu);
  }

  viewGuildOwnerContextMenu(event: MouseEvent, menu: NzDropdownMenuComponent) {
    event.preventDefault();
    this.nzContextMenuService.create(event, menu);
  }

  setUpTabs() {
    this.route.firstChild?.paramMap.subscribe((params) => {
      this.selectedTab.next(String(params.get('tab')));
      this.guildUrlName = String(params.get('guildName'));
    });
  }

  setVisibilityStatus() {
    if (this.guild.visibility === this.guildVisibilityType.Private) {
      this.isPrivate = true;
    } else {
      this.isPrivate = false;
    }
  }

  saveGuild(guild: IGuild) {
    const lastVisitedGuild: LastVisitedGuild = { id: guild.id, userId: this.currentUser.id };
    this.localStorageService.set('lastVisitedGuild', lastVisitedGuild);
  }

  onTabChange(event: NzTabChangeEvent) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.selectedTab.next(this.tabs.value[event.index!]);
    this.tabInformation = {
      guildId: this.guild.id,
      lastSelectedTab: this.selectedTab.value
    };
    this.localStorageService.set('lastOpenedGuildTab', this.tabInformation);
  }

  getGuildMembers() {
    this.isLoading = true;
    this.selectedGuildMembers.value.length = 0;
    this.guildService.getGuildMembers(this.guild.id).subscribe({
      next: async (gm) => {
        if (this.guild.visibility === EGuildVisibilityType.Private) {
          this.guildWaitlistMembers = gm.filter(member => member.status === this.guildMemberStatus.Pending);
        }
        this.guildMembers = gm;
        this.setGuildMemberSelectedStates();
        this.guildMembers.forEach(member => {
          if (this.guildMemberSelectedStates.get(member.id) === true) {
            this.selectedGuildMembers.value.push(member);
          }
        });
        this.filterGuildMembersByStatus(gm);
        this.filterSelectedGuildRoles();
        await this.getCurrentGuildMember();
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`Failed to load guild members. Error: ${error} `);
        this.isLoading = false;
      },
    });
  }

  filterGuildMembersByStatus(guildMembers: IGuildMember[]) {
    this.filteredGuildMembersByStatus = guildMembers.filter(member => member.status === this.guildMemberStatus.Approved);

    this.memberRoleOwners = this.filteredGuildMembersByStatus.filter((member) => member.memberType === this.guildRoleType.Owner)
      .map((member: IGuildMember) => ({ guildMember: member, checked: this.guildMemberSelectedStates.get(member.id) || false }));

    this.memberRoleLeaders = this.filteredGuildMembersByStatus.filter((member) => member.memberType === this.guildRoleType.Leader)
      .map((member: IGuildMember) => ({ guildMember: member, checked: this.guildMemberSelectedStates.get(member.id) || false }));

    this.memberRoleMembers = this.filteredGuildMembersByStatus.filter((member) => member.memberType === this.guildRoleType.Member)
      .map((member: IGuildMember) => ({ guildMember: member, checked: this.guildMemberSelectedStates.get(member.id) || false }));

    this.memberRoleNewbies = this.filteredGuildMembersByStatus.filter((member) => member.memberType === this.guildRoleType.Newbie)
      .map((member: IGuildMember) => ({ guildMember: member, checked: this.guildMemberSelectedStates.get(member.id) || false }));

  }

  removeGuildMember() {
    //TODO: Temporarily commmented due to changes. Will address in the next PR. --Sam

    // this.guildService.removeGuildMember(this.guild.id, this.currentGuildMember.userId).subscribe({
    //   next: (res) => {
    //     if (res) {
    //       const memberIndex = this.guildMembers.findIndex((gm) => gm.id === this.currentGuildMember.id);
    //       this.guildMembers.splice(memberIndex, 1);
    //       this.message.success(`Removed member: ${ this.currentGuildMember.firstName } ${ this.currentGuildMember.surname } `);
    //     }
    //   },
    //   error: (error) => {
    //     this.notification.error(`Failed to remove member: ${ this.currentGuildMember.firstName } ${ this.currentGuildMember.surname } `, error.message);
    //   },
    // });
  }

  removeUserFromWaitlist(userId: string, event: Event) {
    event.stopPropagation();
    this.guildService.removeUserFromWaitlist(this.guild.id, userId).subscribe({
      next: (res) => {
        if (res) {
          const waitlistMemberIndex = this.guildWaitlistMembers.findIndex((gwm) => gwm.userId === userId);
          const waitlistMember = this.guildWaitlistMembers[waitlistMemberIndex];
          this.guildWaitlistMembers.splice(waitlistMemberIndex, 1);
          this.message.success(`Removed ${waitlistMember.firstName} ${waitlistMember.surname} from the wailist.`);
        }
      },
      error: (error) => {
        this.message.error(`Failed to remove user with id: ${userId} from the waitlist.`, error.message);
      }
    });
  }

  approveUserFromWaitlist(userId: string, event: Event) {
    event.stopPropagation();
    this.approveGuildWaitlistUser(userId);
  }

  getCurrentGuildMember(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountDetailsService.getUserProfileSubscription().subscribe({
        next: (res) => {
          this.currentUser = res;
          if (!this.guildMembers) {
            throw new Error('The guild members list has not yet been set. This should not be possible.');
          }

          const guildMemberIndex = this.guildMembers.findIndex((gm) => gm.userId === this.currentUser.id);
          if (guildMemberIndex !== -1) {
            this.currentGuildMember = this.guildMembers[guildMemberIndex];
            this.isAGuildMember = true;
            this.isUserAGuildOwner();
          }
          this.saveGuild(this.guild);
          resolve()
        },
        error: (error) => {
          console.error(`Failed to get the current user.Error: ${error} `);
          reject(error);
        },
      });
    });
  }

  isUserAGuildMember(member: IGuildMember): boolean {
    if (!this.guildMembers) {
      throw new Error('The guild members list has not yet been set. This should not be possible if we\'re checking if a user is a guild member');
    }

    return this.guildMembers.includes(member);
  }

  isUserAGuildOwner(): void {
    if (this.currentUser.id === this.guild.createdById) {
      this.isAGuildOwner = true;
    } else {
      this.isAGuildOwner = false;

      if (this.sharedPlaylistComponent) {
        this.sharedPlaylistComponent.isInteractable = false;
      }

      if (this.guildTopActivitiesComponent) {
        this.guildTopActivitiesComponent.isEditable = false;
      }
    }
  }

  requestToJoinGuild() {
    this.guildService.requestToJoinGuild(this.guild.id).subscribe({
      next: (res) => {
        this.currentGuildMember = res;
        this.handleJoiningGuild.emit(this.guild);
      },
      error: (error) => {
        console.error(`Failed to request to join guild.Error: ${error.message} `);
      }
    });
  }

  joinGuild() {
    this.guildService.joinGuild(this.guild.id).subscribe({
      next: (guild) => {
        this.isAGuildMember = true;
        this.getGuildMembers();
        this.message.success(`Woo Hoo! Successfully joined '${guild.name}' 👏`);
        this.handleJoiningGuild.emit(this.guild);
      },
      error: (error) => {
        console.error(`Failed to join guild.Error: ${error.message} `);
      }
    });
  }

  isPendingApproval() {
    return this.currentGuildMember.status === this.guildMemberStatus.Pending;
  }

  editGuild() {
    this.editGuildMode = EditMode.edit;
  }

  cancelEdit() {
    this.editGuildMode = EditMode.new;
  }

  submitEditedGuild(editedGuild: IGuild) {
    this.guildService.editGuild(editedGuild).subscribe({
      next: (g) => {
        this.guild = g;
        const updatedUrl = encodeUrlComponent(editedGuild.name);
        this.location.replaceState(`/guilds/home/${updatedUrl}/about`);
        this.message.success(`The guild '${editedGuild.name}' has been updated`);
        this.cancelEdit();
        this.setupGuildHomepage();
      },
      error: (error) => {
        this.message.error('Failed to update guild', error.message);
      },
    });
  }

  checkGuildEditStatus(status: boolean) {
    if (!status) {
      this.editGuildMode = EditMode.new;
    }
  }

  shareGuild() {
    const hostName = this.routingStateService.getHostName();
    const publicUrl = this.currentUser.publicUrl;
    const guildNameSlugified = encodeUrlComponent(this.guild.name);

    this.inviteService.generateGuildInvitationCode(this.guild.id).subscribe({
      next: (res: IInvitation) => {
        const clipboardContents = `${hostName}/guilds/${guildNameSlugified}/from/${publicUrl}/${res.inviteCode}`;
        this.clipboard.copy(clipboardContents);
        this.message.success(`An invitation to this guild has been copied to clipboard.`);
      },
      error: (error) => {
        this.message.error(`Error: Failed to generate a Guild invitation for you. Unable to connect to SeaLadder HQ. Check network connection? ${error}`);
      }
    });
  }

  leaveGuild() {
    this.modal.confirm({
      nzTitle: 'Confirm Leave...',
      nzContent: `Are you sure you want to leave guild '${this.guild.name}'?`,
      nzOnOk: () => this.leaveGuildConfirmed(),
    });
  }

  searchForUsers() {
    setTimeout(() => {
      this.isSearchingForUsers = !this.isSearchingForUsers;
    });
  }

  startUserSearch(query: string) {
    this.userSearchInputValue = query;
    this.searchForUsersSubject.next(this.userSearchInputValue);
  }

  conductUserSearch() {
    this.accountDetailsService.searchForUsers(this.userSearchInputValue).subscribe({
      next: (res) => {
        this.userSearchResults = res.filter(user =>
          !this.guildMembers?.some(member => member.userId === user.userId && (member.status === this.guildMemberStatus.Approved || member.status === this.guildMemberStatus.Pending))
        );
      },
      error: (error) => {
        console.error(`Failed to conduct search for users: ${error.message} `);
        this.message.error(`Error: Failed to generate a Guild invitation for you. Unable to connect to SeaLadder HQ. Check network connection?`);
      }
    });
  }

  navigateToMatchedUserPublicProfile(userPublicUrl: string) {
    this.isMatchedUserSelected = true;
    if (this.isAGuildOwner || this.currentGuildMember.memberType === this.guildRoleType.Leader) {
      this.router.navigate(['/profile', userPublicUrl, this.guild.id, true, 'guild-invite']);
    } else {
      this.router.navigate(['/profile', userPublicUrl, this.guild.id, false, 'guild-invite']);
    }
  }

  onBlur(): void {
    this.blurSubject.next();
  }

  private approveGuildWaitlistUser(userId: string) {
    this.guildService.approveUserFromWaitlist(this.guild.id, userId).subscribe({
      next: (res) => {
        if (res) {
          if (!this.guildMembers) {
            throw new Error('The guild members list has not yet been set. This should not be possible if we are already approving users from a waitlist.');
          }
          const waitlistMemberIndex = this.guildWaitlistMembers.findIndex((gwm) => gwm.userId === userId);
          const waitlistMember = this.guildWaitlistMembers[waitlistMemberIndex];
          waitlistMember.status = this.guildMemberStatus.Approved;
          this.guildWaitlistMembers.splice(waitlistMemberIndex, 1);
          this.guildMembers.push(waitlistMember);
          this.memberRoleNewbies.push({ guildMember: waitlistMember, checked: false } as CheckedGuildMember);
          this.message.success(`Approved ${waitlistMember.firstName} ${waitlistMember.surname}  into the guild.`);
        }
      },
      error: (error) => {
        this.message.error(`Failed to approve with id: ${userId} into the guild.`, error.message);
      }
    });
  }

  private setGuildMemberSelectedStates() {
    if (!this.guildMembers) {
      throw new Error('The guild members list has not yet been set. This should not be possible by the time we get to setGuildMemberSelectedStates.');
    }

    this.guildMembers.forEach(member => {
      const isSelected = this.guildMemberSelectedStates.get(member.id);
      this.guildMemberSelectedStates.set(member.id, isSelected === true ? true : false);
    });
  }

  private resetGuildMemberSelectedStates() {
    if (!this.guildMembers) {
      throw new Error('The guild members list has not yet been set. This should not be possible by the time we get to resetGuildMemberSelectedStates.');
    }
    this.guildMembers.forEach(member => {
      this.guildMemberSelectedStates.set(member.id, false);
    });
  }

  private leaveGuildConfirmed() {
    this.guildService.leaveGuild(this.guild).subscribe({
      next: () => {
        this.isAGuildMember = false;
        this.message.info(`You're no longer a member of '${this.guild.name}'`);
        this.handleLeavingGuild.emit(this.guild);
        this.userLeftGuild.emit(this.guild);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.message.error('Failed to leave guild. Ensure you are still apart of the guild and not the current owner, and try again');
        } else {
          console.error('Failed to leave guild: ' + error);
          this.message.error('Failed to leave guild. We can\'t explain why right now. Perhaps try again');
        }
      },
    });
  }

  private resetGuildElements() {
    if (this.editGuildMode === EditMode.edit) {
      this.editGuildMode = EditMode.new;
    }
    this.exitSelectMode();
    this.currentGuildMember = {} as IGuildMember;
    this.isAGuildMember = false;
    this.isAGuildOwner = false;
  }

  private setMetaTags(title: string, description: string, imageUrl: string): void {
    const metaTags = [
      { name: 'description', content: description },
      { name: 'keywords', content: `SeaLadder, ${title}, guild, professional development` },
      { property: 'og:title', content: `${title} | SeaLadder` },
      { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: imageUrl ? imageUrl : '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: `https://www.sealadder.com/guilds/home/${encodeUrlComponent(title)}` },
      { name: 'twitter:title', content: `${title} | SeaLadder` },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: imageUrl ? imageUrl : '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, `${title} | SeaLadder`);
  }
}
