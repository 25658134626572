// framework
import { Component, OnInit } from '@angular/core';

// models
import { IProvider } from '../../models/provider.model';
import { IProviderConfig } from 'src/app/models/provider-config.model';

// services
import { ProvidersService } from '../../services/providers.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'sl-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.less'],
})
export class ProvidersComponent implements OnInit {
  providers!: IProvider[];
  providerConfigurations: IProviderConfig[] = [];

  constructor(private providersService: ProvidersService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.getProviders();
    this.getOAuthProviderConfigs();
  }

  public getProviders(): void {
    this.providersService.getProviders().subscribe((res) => {
      this.providers = res;
    });
  }

  public onProviderLoggedOut(): void {
    this.getProviders();
    this.getOAuthProviderConfigs();
  }

  // Called from the UI to populat the config for each of the providers based on what was setup during initialisation.
  public getProviderConfig(oauthProviderName: string): IProviderConfig | undefined {
    const theProviderConfig = this.providerConfigurations.find((p) => p.providerName === oauthProviderName);
    return theProviderConfig;
  }

  // --- OAuth Provider Configs ---
  private getOAuthProviderConfigs() {
    this.providersService.getOAuthProviderConfigs().subscribe({
      next: (ret) => {
        // ret.forEach((r) => console.log(`Provider config for ${r.providerName}`));
        this.providerConfigurations = ret;
      },
      error: (error) => {
        console.error(error);
        this.message.error(`Oh no, sorry about this, but we currently can't connect right now.`);
      },
    });
  }
}
