import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from './navigation.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';

export interface CardInfo {
  iconPath: string;
  name: string;
  description: string;
}

@Component({
  selector: 'sl-landing-v2',
  templateUrl: './landing-v2.component.html',
  styleUrls: ['./landing-v2.component.less']
})
export class LandingV2Component implements OnInit {
  lifeLongLearningIcons!: CardInfo[];
  learningTypes!: string[];
  currentDate!: Date;
  googleFormExpiryDate!: Date;

  constructor(private router: Router, private navigationService: NavigationService, private metaTagService: MetaTagService) { }

  ngOnInit(): void {
    this.initLifeLongLearningIcons();
    this.initLearningTypes();
    this.setMetaTags();
    this.currentDate = new Date();
    this.googleFormExpiryDate = new Date(this.currentDate.getFullYear(), 8, 31);
  }

  initLifeLongLearningIcons() {
    this.lifeLongLearningIcons = [
      {
        iconPath: '/assets/img/landing/icon_target.png',
        name: 'Identify',
        description: 'Select your career goal'
      },
      {
        iconPath: '/assets/img/landing/icon_influence.png',
        name: 'Choose',
        description: 'Prioritise in demand skills'
      },
      {
        iconPath: '/assets/img/landing/icon_tick_mark.png',
        name: 'Focus',
        description: 'Get learning recommendations'
      },
      {
        iconPath: '/assets/img/landing/icon_fingerprint.png',
        name: 'Evidence',
        description: 'Manage & validate learning activities'
      },
      {
        iconPath: '/assets/img/landing/icon_curriculum_vitae.png',
        name: 'Showcase',
        description: 'Present your skills portfolio'
      },
      {
        iconPath: '/assets/img/landing/icon_link.png',
        name: 'Connect',
        description: 'Impact industry professionals'
      }
    ];
  }

  navigateOnCallToAction(joinNow: boolean) {
    if (this.expiredCallToAction()) {
      if (joinNow) {
        this.navigationService.navigate("https://www.sealadder.com/Account/Register?inviteCode=cYpt7yZG");
      } else {
        this.navigationService.navigate("https://docs.google.com/forms/d/e/1FAIpQLSdE1Magd2x8oHdqsMoBmJ8mV1SF2sb4DmvTXCrUXWvRAMYv-w/viewform");
      }
    } else {
      this.router.navigate(['register']);
    }
  }

  initLearningTypes() {
    this.learningTypes = [
      'Articles',
      'Audio books',
      'Blogs',
      'Books',
      'Certifications',
      'Conference Recordings',
      'Courses',
      'Online Testing',
      'Podcasts',
      'Presentations',
      'Professional training',
      'Personal Projects',
      'Tutorial / How-to',
      'Videos',
    ];
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
      { name: 'keywords', content: 'SeaLadder, career control, lifelong learning, skills development, software engineers, data science, tech ops, professional coaching' },
      { property: 'og:title', content: 'SeaLadder | Take Control of Your Career' },
      { property: 'og:description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/landing/computer_horizon.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/landing' },
      { name: 'twitter:title', content: 'SeaLadder | Take Control of Your Career' },
      { name: 'twitter:description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
      { name: 'twitter:image', content: '/assets/img/landing/computer_horizon.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'SeaLadder | Take Control of Your Career');
  }

  expiredCallToAction(): boolean {
    return this.currentDate > this.googleFormExpiryDate;
  }
}
