import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  private defaultTags = [
    { name: 'description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
    { name: 'keywords', content: 'SeaLadder, career control, lifelong learning, skills development, software engineers, data science, tech ops, professional coaching' },
    { property: 'og:title', content: 'SeaLadder' },
    { property: 'og:description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: 'SeaLadder' },
    { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
    { property: 'og:url', content: 'https://www.sealadder.com' },
    { name: 'twitter:title', content: 'SeaLadder' },
    { name: 'twitter:description', content: 'SeaLadder is the lifelong learning and skills platform for software engineers, data scientists, and tech ops professionals. Join now to take control of your career and access exclusive coaching offers.' },
    { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
  ];

  constructor(private titleService: Title, private metaService: Meta) { }

  setMetaTags(tags: { name?: string; property?: string; content: string }[], title: string): void {
    this.titleService.setTitle(title);
    tags.forEach(tag => this.metaService.updateTag(tag));
  }

  clearMetaTags(tags: { name?: string; property?: string; content: string }[]): void {
    tags.forEach(tag => {
      const selector = tag.name ? `name="${tag.name}"` : `property="${tag.property}"`;
      this.metaService.removeTag(selector);
    });
  }

  setDefaultMetaTags(): void {
    this.setMetaTags(this.defaultTags, 'SeaLadder');
  }
}