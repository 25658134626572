import { AfterViewInit, Component } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NzCardModule } from 'ng-zorro-antd/card';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IPricingPlan } from 'src/app/models/pricing-plan.model';
import { MetaTagService } from 'src/app/services/meta-tag.service';


@Component({
  selector: 'sl-pricing',
  standalone: true,
  imports: [CommonModule, NzModalModule, NzButtonModule, NzCardModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.less'
})
export class PricingComponent implements AfterViewInit {

  public pricingPlans: IPricingPlan[] = [];

  constructor(private router: Router, private metaTagService: MetaTagService) {
    this.initPricingPlans();
    this.setMetaTags();
  }

  public initPricingPlans(): void {
    this.pricingPlans = [{
      title: "Student",
      price: {
        original: 5,
        discounted: 1,
      },
      description: "Try us out free of charge, no credit card required!",
      features: [
        "1 month free trial",
        "Discount 80% for 3 months",
        "Unlimited goals",
        "Personalised learning",
        "Cohort onboarding",
        "Activity tracking",
        "Centralise accounts",
        "Access to Guilds"
      ]
    },
    {
      title: "Pro",
      price: {
        original: 10,
        discounted: 4,
      },
      description: "Try us out free of charge, no credit card required!",
      features: [
        "1 month free trial",
        "Discount 60% for 3 months",
        "Unlimited goals",
        "Personalised learning",
        "Cohort onboarding",
        "Activity tracking",
        "Centralise accounts",
        "Access to Guilds",
        "Mentorship opportunities",
        "Guild leadership opportunities",
      ]
    }];
  }

  ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".pricing-info", {
      transform: "translateX(0)",
      duration: 1.5,
      scrollTrigger: {
        trigger: ".pricing-info",
      }
    });
  }

  onJoinNow() {
    const currentDate = new Date();
    const googleFormExpiryDate = new Date(currentDate.getFullYear(), 7, 31);

    if (currentDate <= googleFormExpiryDate) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdE1Magd2x8oHdqsMoBmJ8mV1SF2sb4DmvTXCrUXWvRAMYv-w/viewform";
    } else {
      this.router.navigate(['register']);
    }
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Join SeaLadder during our Beta Launch and receive massively discounted rates for the first 3 months. Enjoy personalized onboarding, professional training, and a 4-week course ending with a shared project.' },
      { name: 'keywords', content: 'SeaLadder, pricing, Beta Launch, discounts, personalized onboarding, professional training, cohort program' },
      { property: 'og:title', content: 'SeaLadder | Pricing' },
      { property: 'og:description', content: 'Join SeaLadder during our Beta Launch and receive massively discounted rates for the first 3 months. Enjoy personalized onboarding, professional training, and a 4-week course ending with a shared project.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/pricing' },
      { name: 'twitter:title', content: 'SeaLadder | Pricing' },
      { name: 'twitter:description', content: 'Join SeaLadder during our Beta Launch and receive massively discounted rates for the first 3 months. Enjoy personalized onboarding, professional training, and a 4-week course ending with a shared project.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'SeaLadder | Pricing');
  }
}
