import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// models
import { ProfilePageModel } from '../../models/profile-page.model';
import { IUserActivity } from '../../models/user-activity.model';

// services
import { AppDetailsService } from 'src/app/services/app-details.service';
import { UserActivitiesService } from '../../services/user-activities.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { AccountDetailsService } from 'src/app/services/account-details.service';

export interface Tab {
  label: string;
  link: string;
  index: number;
}

@Component({
  selector: 'sl-profile-v2',
  templateUrl: './profile-v2.component.html',
  styleUrls: ['./profile-v2.component.less'],
})
export class ProfileV2Component implements OnInit {
  activityStatus!: ProfilePageModel[];
  activitiesList!: IUserActivity[];
  tabs: Tab[] | undefined;
  refreshUserActivityMetric = false;

  constructor(private accountDetailsService: AccountDetailsService, private userActivitiesService: UserActivitiesService, private appDetailsService: AppDetailsService, public router: Router, private metaTagService: MetaTagService) {
    this.tabs = [this.createTab('Overview', 'overview', 0), this.createTab('Activities', 'activities', 1), this.createTab('Providers', 'providers', 2), this.createTab('Playlists', 'playlists', 3)];
    this.appendTabset();
  }

  ngOnInit() {
    this.setMetaTags();
    this.loadUserActivities();
  }

  // Playlists tab is appended to tabset if version is not Production. Can be moved to constructor when complete.
  private appendTabset() {
    if (!this.appDetailsService.isProduction()) {
      this.tabs?.push
        // this.createTab('Playlists', 'playlists', 3), //  TODO: Put any other "new" tabs in here during development.
        ();
    }
  }

  private createTab(label: string, link: string, index: number): Tab {
    return {
      label,
      link,
      index,
    };
  }

  private loadUserActivities() {
    this.activitiesList = this.userActivitiesService.getUserActivities();
    this.setActivitySummary(this.activitiesList);

    this.userActivitiesService.getActivitiesUpdatedEvent().subscribe(
      () => {
        this.refreshUserActivityMetric = true;
        this.activitiesList = this.userActivitiesService.getUserActivities();
        this.setActivitySummary(this.activitiesList);
        this.refreshUserActivityMetric = false;
      },
      (err) => console.error(`*** ProfileV2Component: Failed to get activities updating event. Error: ${err}`)
    );
  }

  private setActivitySummary(activityList: IUserActivity[]): void {
    this.activityStatus = [
      {
        count: activityList.filter((x) => x.status === 'notstarted').length,
        type: 'Scheduled',
      },
      {
        count: activityList.filter((x) => x.status === 'started').length,
        type: 'In Progress',
      },
      {
        count: activityList.filter((x) => x.status === 'completed').length,
        type: 'Completed',
      },
    ];
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Manage and view your SeaLadder profile. Track your activities, update your information, and navigate through different sections of your user profile.' },
      { name: 'keywords', content: 'SeaLadder, user profile, manage profile, track activities, professional development' },
      { property: 'og:title', content: 'SeaLadder | User Profile' },
      { property: 'og:description', content: 'Manage and view your SeaLadder profile. Track your activities, update your information, and navigate through different sections of your user profile.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/profile' },
      { name: 'twitter:title', content: 'SeaLadder | User Profile' },
      { name: 'twitter:description', content: 'Manage and view your SeaLadder profile. Track your activities, update your information, and navigate through different sections of your user profile.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.accountDetailsService.getUserProfileSubscription().subscribe({
      next: (res) => {
        if (res) {
          this.metaTagService.setMetaTags(metaTags, `${res.name} | SeaLadder`);
        } else {
          this.metaTagService.setMetaTags(metaTags, 'SeaLadder | User Profile');
        }
      },
      error: (error) => {
        console.error("We couldn't get the user profile", error)
        this.metaTagService.setMetaTags(metaTags, 'SeaLadder | User Profile');
      }
    })
  }
}
