import { Component, OnInit } from '@angular/core';
import { MetaTagService } from 'src/app/services/meta-tag.service';

@Component({
  selector: 'sl-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
})
export class AdminComponent implements OnInit {
  constructor(private metaTagService: MetaTagService) { }

  ngOnInit(): void {
    this.setMetaTags();
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Access the SeaLadder Admin Portal to manage various aspects of the platform, including seeding the database, viewing metrics, managing user activities, and more.' },
      { name: 'keywords', content: 'SeaLadder, admin portal, manage platform, user activities, metrics, database seeding, professional development' },
      { property: 'og:title', content: 'SeaLadder | Admin Portal' },
      { property: 'og:description', content: 'Access the SeaLadder Admin Portal to manage various aspects of the platform, including seeding the database, viewing metrics, managing user activities, and more.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/admin' },
      { name: 'twitter:title', content: 'SeaLadder | Admin Portal' },
      { name: 'twitter:description', content: 'Access the SeaLadder Admin Portal to manage various aspects of the platform, including seeding the database, viewing metrics, managing user activities, and more.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'Admin Portal');
  }
}
