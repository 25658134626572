import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MetaTagService } from 'src/app/services/meta-tag.service';

// services
import { RegisterService } from 'src/app/services/register.service';

@Component({
  selector: 'sl-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
})
export class RegisterComponent implements OnInit {
  formValues!: UntypedFormGroup;
  specialisations!: string[];
  isVisible = false;

  constructor(private fb: UntypedFormBuilder, private registerService: RegisterService, private router: Router, private metaTagService: MetaTagService) { }

  ngOnInit(): void {
    this.initCurrentRoles();
    this.initFormFields();
    this.setMetaTags();
  }

  initCurrentRoles(): void {
    this.specialisations = [
      // TODO: We REALLY should just get this from the backend, but this is fine for the landing page
      'Backend Developer',
      'Data Engineer',
      'DevOps',
      'Data Analyst',
      'Data Scientist',
      'Mobile Developer',
      'Frontend Developer',
      'Games Developer',
      'Other',
    ];
  }

  initFormFields(): void {
    this.formValues = this.fb.group({
      firstName: '',
      lastName: '',
      email: ['', [Validators.required]],
      city: '',
      profession: '',
    });
  }

  showModal(): void {
    this.isVisible = true;
    this.registerService.register(this.formValues).subscribe(
      () => { },
      (err) => {
        console.error(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.router.navigate(['landing']);
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Join the waitlist for SeaLadder and be among the brightest and the best. Set clear goals, develop your skills, and enhance how you are seen by employers.' },
      { name: 'keywords', content: 'SeaLadder, join waitlist, career goals, skills development, professional growth, lifelong learning' },
      { property: 'og:title', content: 'SeaLadder | Join the Waitlist' },
      { property: 'og:description', content: 'Join the waitlist for SeaLadder and be among the brightest and the best. Set clear goals, develop your skills, and enhance how you are seen by employers.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/register' },
      { name: 'twitter:title', content: 'SeaLadder | Join the Waitlist' },
      { name: 'twitter:description', content: 'Join the waitlist for SeaLadder and be among the brightest and the best. Set clear goals, develop your skills, and enhance how you are seen by employers.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'SeaLadder | Join the Waitlist');
  }
}
