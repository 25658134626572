import { AuthService } from './services/auth.service';
import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { RoutingStateService } from './services/routing-state.service';
import { GtmService } from './services/gtm.service';
import { MetaTagService } from './services/meta-tag.service';

@Component({
  selector: 'sl-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  loadingApplication = true;

  isLoggedIn: boolean | undefined;

  constructor(private authService: AuthService, routingStateService: RoutingStateService, private gtmService: GtmService, private metaTagService: MetaTagService) {
    routingStateService.loadRouting();
    // console.log(`*** AppComponent: is constructing...`);
  }

  ngOnInit(): void {
    // console.log(`*** AppComponent: LoadingApplication`);
    this.metaTagService.setDefaultMetaTags()

    this.authService.isLoggedInChange().subscribe(
      (loggedIn) => {
        // console.log(`*** AppComponent: Change isLoggedIn to: ${loggedIn}`);
        this.isLoggedIn = loggedIn;
        this.gtmService.triggerEvent('eventLoggedIn', this.authService.email);
      },
      (error) => {
        console.error(`*** AppComponent: error rom isLoggedInChange. Error: ${JSON.stringify(error)}`);
      }
    );
  }

  ngAfterViewChecked(): void {
    this.loadingApplication = false;
  }
}
