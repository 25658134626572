import { Component, OnInit } from '@angular/core';
import { MetaTagService } from 'src/app/services/meta-tag.service';

@Component({
  selector: 'sl-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.less']
})
export class TermsComponent implements OnInit {

  constructor(private metaTagService: MetaTagService) { }

  ngOnInit(): void {
    this.setMetaTags();
  }

  private setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Read the terms of service for using SeaLadder. Understand the rules, regulations, and guidelines for accessing and using our website.' },
      { name: 'keywords', content: 'SeaLadder, terms of service, user agreement, website terms, legal' },
      { property: 'og:title', content: 'Terms of Service | SeaLadder' },
      { property: 'og:description', content: 'Read the terms of service for using SeaLadder. Understand the rules, regulations, and guidelines for accessing and using our website.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/terms' },
      { name: 'twitter:title', content: 'Terms of Service | SeaLadder' },
      { name: 'twitter:description', content: 'Read the terms of service for using SeaLadder. Understand the rules, regulations, and guidelines for accessing and using our website.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'Terms of Service | SeaLadder');
  }
}
