import { LocalStorageService } from 'ngx-localstorage';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, ViewChild, ElementRef, OnInit, Input, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

// models
import { IGuild, encodeUrlComponent, decodeUrlComponent } from '../../models/guild.model';

// services
import { GuildService } from 'src/app/services/guild.service';
import { LastSelectedTab, LastVisitedGuild } from '../guild-home/guild-home.component';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { AuthService } from 'src/app/services/auth.service';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { SearchGuildsComponent } from '../search-guilds/search-guilds.component';
import { MetaTagService } from 'src/app/services/meta-tag.service';

@Component({
  selector: 'sl-guilds',
  templateUrl: './guilds.component.html',
  styleUrls: ['./guilds.component.less'],
})
export class GuildsComponent implements OnInit, OnDestroy {
  @ViewChild('guildCards') gc!: ElementRef;
  @ViewChild(SearchGuildsComponent) searchGuildsComponent!: SearchGuildsComponent;
  @Input() refreshMyGuilds = false;
  @Input() currentGuild!: IGuild | null;
  @Input() currentGuildId!: number | null;

  myGuilds: IGuild[] = [];
  guildSearchResultsBeingShown = false;
  isSearchingForGuilds = false;
  searchResultGuilds: IGuild[] | undefined = [];
  checkboxToggled = false;
  isAdmin = false;
  currentUser!: IUserProfileSubscription;

  userUrl!: string;
  inviteCode!: string;

  destroyComponent = new Subject<void>();
  isLoading = false;

  constructor(private guildService: GuildService, private router: Router, private localStorageService: LocalStorageService, private route: ActivatedRoute, private accountDetailsService: AccountDetailsService, private authService: AuthService,
    private notification: NzNotificationService, private metaTagService: MetaTagService) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.updateisAdmin();
    this.subscribeToRouterEvents();
    this.setMetaTags();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  get isHomeGuildRoute(): boolean {
    return this.router.url === '/guilds';
  }

  get isCreatingGuild(): boolean {
    return this.router.url === '/guilds/create';
  }

  subscribeToRouterEvents() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroyComponent)
    ).subscribe(event => {
      if ((event as NavigationEnd).urlAfterRedirects === '/guilds' && !this.isSearchingForGuilds) {
        this.setMetaTags();
        this.searchGuildsComponent.searchForGuilds();
      }
    });
  }

  createGuild() {
    if (this.checkboxToggled) {
      this.checkboxToggled = false;
    }
    this.router.navigate(['/guilds/create']);
  }

  getGuildByName() {
    const paramMap = this.route.firstChild?.snapshot.paramMap;
    if (!paramMap || paramMap.keys.length === 0) {
      this.searchGuildsComponent.searchForGuilds();
      return;
    }

    const guildName = paramMap.get('guildName');
    if (!guildName) {
      this.searchGuildsComponent.searchForGuilds();
      return;
    }

    this.guildService.getGuildByName(decodeUrlComponent(guildName)).subscribe({
      next: (guild) => {
        this.handleSelectedGuild(guild);
      },
      error: (error) => {
        console.error(`Failed to load guild by name. Error: ${error}`);
        this.notification.error('Error', 'Failed to load guild');
        this.searchGuildsComponent.searchForGuilds();
      }
    });
  }

  handleCreatedGuild(guild: IGuild) {
    this.currentGuild = guild;
    this.currentGuildId = guild.id;
    this.myGuilds.push(guild);
    this.myGuilds = [...this.myGuilds];
    this.handleSelectedGuild(guild);
  }

  handleCancelledGuildCreation(status: boolean) {
    if (!status) {
      this.router.navigateByUrl('/guilds');
    }
  }

  handleLeavingGuild(guild: IGuild) {
    const guildIndex = this.myGuilds.findIndex((g) => g.id === guild.id);
    this.myGuilds.splice(guildIndex, 1);
    this.localStorageService.remove('lastVisitedGuild');
    this.router.navigateByUrl('/guilds');
  }

  handleJoiningGuild(guild: IGuild) {
    const guildIndex = this.searchResultGuilds!.findIndex((g) => g.id === guild.id);
    this.searchResultGuilds?.splice(guildIndex, 1);
    this.searchResultGuilds = [...this.searchResultGuilds!];
    this.myGuilds.push(guild);
    this.myGuilds = [...this.myGuilds];
  }

  handleSelectedMyGuildsIcon(guild: IGuild) {
    this.currentGuild = guild;
    this.currentGuildId = guild.id;
  }

  handleCheckboxState(state: boolean) {
    this.checkboxToggled = state;
    if (this.router.url !== '/guilds') {
      if (this.checkboxToggled) {
        this.router.navigateByUrl('/guilds');
      }
    } else {
      this.searchGuildsComponent.searchForGuilds();
    }
  }

  handleSelectedGuild(guild: IGuild) {
    const guildTabDetails: LastSelectedTab = this.localStorageService.get<LastSelectedTab>('lastOpenedGuildTab')!;
    const queryParams = this.route.snapshot.queryParams;
    let selectedTab = '';

    if (this.checkboxToggled) {
      this.checkboxToggled = false;
    }

    if (guildTabDetails && guild.id === Number(guildTabDetails.guildId) && Object.keys(queryParams).length === 0) {
      selectedTab = guildTabDetails.lastSelectedTab;
    } else {
      selectedTab = 'about';
    }

    if (guild.externalLinks && guild.skills && (guild.relatedTrackName || guild.relatedTrackId) && guild.location) {
      this.currentGuild = guild;
      this.currentGuildId = guild.id;

      if (queryParams.goalId && queryParams.playlistId) {
        this.router.navigate([`guilds/home/${encodeUrlComponent(guild.name)}/playlists`], { queryParams: { goalId: queryParams.goalId, playlistId: queryParams.playlistId } });
      } else if (queryParams.goalId && queryParams.activityId) {
        this.router.navigate([`guilds/home/${encodeUrlComponent(guild.name)}/activities`], { queryParams: { goalId: queryParams.goalId, activityId: queryParams.activityId } });
      } else {
        this.router.navigateByUrl(`/guilds/home/${encodeUrlComponent(guild.name)}/${selectedTab}`);
      }

    } else {
      this.guildService.getGuildById(guild.id).subscribe({
        next: (g) => {
          this.currentGuild = g;
          this.currentGuildId = g.id;
          const guildNameEncoded = encodeUrlComponent(guild.name);
          if (queryParams.goalId && queryParams.playlistId) {
            this.router.navigate([`guilds/home/${guildNameEncoded}/playlists`], { queryParams: { goalId: queryParams.goalId, playlistId: queryParams.playlistId } });
          } else if (queryParams.goalId && queryParams.activityId) {
            this.router.navigate([`guilds/home/${guildNameEncoded}/activities`], { queryParams: { goalId: queryParams.goalId, activityId: queryParams.activityId } });
          } else {
            this.router.navigateByUrl(`/guilds/home/${guildNameEncoded}/${selectedTab}`);
          }
        },
        error: (error) => {
          console.error(`Failed to load guild. Error: ${error.message}`);
          this.notification.error('Error', 'Failed to load guild. Try again later?');
        },
      });
    }
  }

  updateisAdmin(): boolean {
    if (!this.authService.isLoggedIn()) {
      this.isAdmin = false;
      return false;
    }

    this.accountDetailsService.getIsAdmin().subscribe({
      next: (ret) => {
        this.isAdmin = ret;
        return true;
      },
      error: (err) => console.error(`*** NavbarSideComponent: getIsAdmin Error. Err = ${err}`),
    });

    return false;
  }

  getCurrentUser() {
    this.accountDetailsService.getUserProfileSubscription().subscribe({
      next: (res) => {
        this.currentUser = res;
        this.getMyGuilds();
      },
      error: (error) => {
        console.error(`Failed to get the current user. Error: ${error}`);
      },
    });
  }

  searchingForGuilds(status: boolean) {
    this.isSearchingForGuilds = status;
    if (!this.isHomeGuildRoute && this.isSearchingForGuilds) {
      this.router.navigateByUrl('/guilds');
    }
  }

  searchResultsBeingShown(guilds: IGuild[]) {
    this.guildSearchResultsBeingShown = true;
    this.searchResultGuilds = guilds;
  }

  userLeftGuild(guild: IGuild) {
    this.myGuilds.splice(this.myGuilds.findIndex((g) => g.id === guild.id), 1);
  }

  setMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Explore and manage guilds on SeaLadder. Join, create, and collaborate with various guilds. Sign up now!' },
      { name: 'keywords', content: 'SeaLadder, guilds, explore guilds, manage guilds, join guilds, create guilds, collaborate' },
      { property: 'og:title', content: 'Guilds | SeaLadder' },
      { property: 'og:description', content: 'Explore and manage guilds on SeaLadder. Join, create, and collaborate with various guilds. Sign up now!' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/guilds' },
      { name: 'twitter:title', content: 'Guilds | SeaLadder' },
      { name: 'twitter:description', content: 'Explore and manage guilds on SeaLadder. Join, create, and collaborate with various guilds. Sign up now!' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'Guilds | SeaLadder')
  }

  private handleNavigatingToMostRecentGuild(lastVisitedGuild: LastVisitedGuild) {
    const guildIndex = this.myGuilds.findIndex((g) => g.id === lastVisitedGuild.id);
    if (-1 !== guildIndex) {
      this.handleSelectedGuild(this.myGuilds[guildIndex]);
    } else {
      this.searchGuildsComponent.searchForGuilds();
    }
  }

  private getMyGuilds() {
    const lastVisitedGuild = this.localStorageService.get<LastVisitedGuild>('lastVisitedGuild');
    const lastVisitedGuildUserId = lastVisitedGuild ? String(lastVisitedGuild.userId) : null;
    const currentUserUserId = this.currentUser ? String(this.currentUser.id) : null;

    this.isLoading = true;
    this.guildService.getMyGuilds().subscribe({
      next: (guilds) => {
        guilds.sort((a, b) => (new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime() ? 1 : -1));
        this.myGuilds = guilds;

        if (!this.isCreatingGuild) {
          if (this.route.firstChild?.snapshot.paramMap && this.route.firstChild.snapshot.paramMap.keys.length !== 0) {
            this.getGuildByName();
          } else if (this.myGuilds.length && lastVisitedGuildUserId === currentUserUserId && this.myGuilds.some(g => g.id === lastVisitedGuild?.id)) {
            if (lastVisitedGuild) {
              this.handleNavigatingToMostRecentGuild(lastVisitedGuild);
            } else {
              this.searchGuildsComponent.searchForGuilds();
            }
          }
          else {
            this.searchGuildsComponent.searchForGuilds();
          }
        }
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.notification.error('Error', 'Failed to retrieve the guilds you are a part of. Try again later?');
        this.searchGuildsComponent.searchForGuilds();
      },
    });
  }
}
