<div class="container-providers">
  <div>
    <h4>Connect your account to the following providers below:</h4>
    <div nz-row>
      <div class="container-cards" *ngFor="let provider of providers" nz-col>
        <sl-provider-card
          [provider]="provider"
          [providerConfig]="getProviderConfig(provider.name)"
          (getProvidersCallback)="getProviders()"
          (providerLoggedOut)="onProviderLoggedOut()">
        </sl-provider-card>
      </div>
    </div>
  </div>
</div>
