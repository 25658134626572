import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { AppDetailsService } from 'src/app/services/app-details.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';

@Component({
  selector: 'sl-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.less'],
})
export class UserDetailsComponent implements OnInit {
  version = '';
  isAdminOrTest = false;
  constructor(private appDetailsService: AppDetailsService, private accountDetailsService: AccountDetailsService, private metaTagService: MetaTagService, private router: Router) { }

  ngOnInit(): void {
    this.appDetailsService.getAppDetailsData().subscribe((res) => {
      //This code is to hide the Billing tab on the production site. To be removed when Billing functionality is complete.
      this.version = res.version;

      if (this.isProduction()) {
        // only call the following if we are on the production site
        this.accountDetailsService.getIsAdmin().subscribe(
          (isAdmin) => {
            if (isAdmin) {
              this.isAdminOrTest = true;
            }
          });

        this.accountDetailsService.getIsTest().subscribe(
          (isTest) => {
            if (isTest) {
              this.isAdminOrTest = true;
            }
          });
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateMetaTags();
    });
    this.updateMetaTags();
  }

  isProduction(): boolean {
    if (this.version.includes('P:')) {
      return true;
    }

    return false;
  }

  private updateMetaTags(): void {
    const currentRoute = this.router.url;
    if (currentRoute.includes('account-details')) {
      this.setAccountDetailsMetaTags();
    } else if (currentRoute.includes('billing-details')) {
      this.setBillingDetailsMetaTags();
    } else {
      this.metaTagService.setDefaultMetaTags();
    }
  }

  private setAccountDetailsMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'View and manage your user details on SeaLadder. Update your account information and billing details easily.' },
      { name: 'keywords', content: 'SeaLadder, user details, account details, billing details, manage account' },
      { property: 'og:title', content: 'SeaLadder | User Details' },
      { property: 'og:description', content: 'View and manage your user details on SeaLadder. Update your account information and billing details easily.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/account-details' },
      { name: 'twitter:title', content: 'SeaLadder | User Details' },
      { name: 'twitter:description', content: 'View and manage your user details on SeaLadder. Update your account information and billing details easily.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'Edit Profile');
  }

  private setBillingDetailsMetaTags(): void {
    const metaTags = [
      { name: 'description', content: 'Manage your billing details on SeaLadder. Update your payment information and view your billing history.' },
      { name: 'keywords', content: 'SeaLadder, billing details, manage billing, update payment, billing history' },
      { property: 'og:title', content: 'SeaLadder | Billing Details' },
      { property: 'og:description', content: 'Manage your billing details on SeaLadder. Update your payment information and view your billing history.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SeaLadder' },
      { property: 'og:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' },
      { property: 'og:url', content: 'https://www.sealadder.com/billing-details' },
      { name: 'twitter:title', content: 'SeaLadder | Billing Details' },
      { name: 'twitter:description', content: 'Manage your billing details on SeaLadder. Update your payment information and view your billing history.' },
      { name: 'twitter:image', content: '/assets/img/sealadder/SL_logo_Cropped_692x422.png' }
    ];

    this.metaTagService.setMetaTags(metaTags, 'Billing Details');
  }
}
